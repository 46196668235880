import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import "./OpenThePage.css";
import LanguageConfiguration from './LanguageConfiguration'
import CHATUI_CONFIG from './config';
// 定义语言文本映射
const languageText = LanguageConfiguration;
const locale = localStorage.getItem('lang') || CHATUI_CONFIG.lang
const lastlocale = locale.substring(locale.length - 2)
const texts = languageText[lastlocale];
document.title = texts.title;
// 语言切换模态框组件
const LanguageSwitchModal = ({ confirmLanguageSwitch, texts, showModal }) => (
    <div className={`modal ${showModal ? 'show' : ''}`}>
        <div className={`modal-content ${showModal ? 'show' : ''}`}>
            <div className="modal-content_content">
                <div className="modal-content-title">{texts.modalTitle}</div>
                <div className="modal-content-content">
                    <div onClick={() => confirmLanguageSwitch('CN')}>{texts.CN}</div>
                    <div onClick={() => confirmLanguageSwitch('HK')}>{texts.HK}</div>
                    <div onClick={() => confirmLanguageSwitch('US')}>{texts.US}</div>
                </div>
            </div>
        </div>
    </div>
);

// 语言切换组件
const LanguageSwitch = ({ languageSwitch, currentLanguage, texts }) => (
    <div className="currentLanguage">
        <span onClick={languageSwitch}>{texts.switchLanguage}</span>
    </div>
);

// 开始按钮组件
const StartButton = ({ currentLanguage, btnShow, onClick, texts }) => (
    <div onClick={onClick} className={`BackgroundImageLink ${btnShow ? 'show' : ''}`}>
        <span className='BackgroundImageText'>{texts.buttonText}</span>
    </div>
);

export default function App() {
    const [btnShow, setBtnShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const locale = localStorage.getItem('lang')
    let localelang = null;
    if (locale) {
        localelang = locale.substring(locale.length - 2)
    } else {
        localelang = "CN";
    }
    const [currentLanguage, setCurrentLanguage] = useState(localelang); // 默认显示简体中文 
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            setBtnShow(true);
        }, 300);
    }, []);

    // 切换语言
    const languageSwitch = () => {
        setShowModal(true);
    };

    const setTitle = () => {
        // 定义语言文本映射
        const locale = localStorage.getItem('lang') || CHATUI_CONFIG.lang
        const lastlocale = locale.substring(locale.length - 2)
        const texts = languageText[lastlocale];
        window.locale = texts.locale
        window.localeInpunt = texts.localeInpunt
        document.title = texts.title;
    };
    // 确认语言切换
    const confirmLanguageSwitch = (lang) => {
        // 确认语言切
        setCurrentLanguage(lang);
        if (lang == 'CN') {
            CHATUI_CONFIG.lang = 'zh-CN';
            localStorage.setItem('lang', 'zh-CN');
            setTitle();
        } else if (lang == 'US') {
            CHATUI_CONFIG.lang = 'en-US';
            localStorage.setItem('lang', 'en-US');
            setTitle();
        } else if (lang == 'HK') {
            CHATUI_CONFIG.lang = 'zh-HK';
            localStorage.setItem('lang', 'zh-HK');
            setTitle();
        }
        setShowModal(false);
    };

    // 开始操作
    const start = () => {
        navigate('/app');
    };

    const texts = languageText[currentLanguage];

    return (
        <div>
            <div className='BackgroundImage'>
                <div className="TopInfo">
                    <span className='TopText'>{texts.topText}</span>
                </div>
                <LanguageSwitch languageSwitch={languageSwitch} currentLanguage={currentLanguage} texts={texts} />
                <StartButton currentLanguage={currentLanguage} btnShow={btnShow} onClick={start} texts={texts} />
                <div className="BottomInfo">
                    <span className='BottomText'>{texts.bottomText}</span>
                </div>
            </div>
            <LanguageSwitchModal confirmLanguageSwitch={confirmLanguageSwitch} texts={texts} showModal={showModal} />
            {/* {showModal && <LanguageSwitchModal confirmLanguageSwitch={confirmLanguageSwitch} texts={texts} />} */}
        </div>
    );
}