import React, { useState, useEffect, useRef } from "react";
import CHATUI_CONFIG from './config';
import Chat, { Bubble, useMessages } from "@chatui/core";
import "@chatui/core/dist/index.css";
import "./chatui-cust-styles.css";
import _ from "lodash";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import TransWorker from 'worker-loader!./transcode.worker.js';
import LanguageConfiguration from './LanguageConfiguration'
// 定义语言文本映射
const languageText = LanguageConfiguration;
let voiceResult = "";
let canRecord = true; // 写个判断逻辑
let step = 0;
let bot = null;
let shareuniqueId = "";
let datamessage = {};
let chat = [];

const XF_APPID = "8929b6c2";
const ALIYUN_APPKEY = "7tekxs7IbbAnXrni"

let testSampleRate = 16000;
let testBitRate = 16;
let SendFrameSize = 12800;
let ali_chunk_size = 3200;
let rec

// localStorage.setItem('lang', 'zh-CN');

let RealTimeSendTryReset = function () {
  realTimeSendTryChunks = null;
};
let realTimeSendTryNumber;
let transferUploadNumberMax;
let realTimeSendTryChunk;
let realTimeSendTryChunks;

function formattedDate() {
  var now = new Date();
  var year = now.getFullYear();
  var month = now.getMonth() + 1;
  var day = now.getDate();
  var hours = now.getHours();
  var minutes = now.getMinutes();
  var seconds = now.getSeconds();
  // 格式化月份和日期为两位数
  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }
  // 格式化小时和分钟为两位数
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return year + "年" + month + "月" + day + "日 " + hours + ":" + minutes + ":" + seconds;
}

// 保存聊天记录
async function saveChatRecord(text, messages, topic = "") {
  // uuID
  if (shareuniqueId == "") {
    // 如果没有，就生成一个新的uuID
    shareuniqueId = uuidv4();
  }

  // 格式化当前时间
  const currentTime = formattedDate();

  // 构建需要发送给后端的数据结构，将 messages 中的聊天记录整理成需要的格式
  // 存储聊天记录发送数据给后端接口
  var chatObj = {
    'input': text,
    'output': messages,
    'topic': topic,
    'chatTime': currentTime,
  }

  chat.push(chatObj);
  let chatHistory = chat;
  // console.log("chatHistory", chatHistory);
  // TODO 改成服务器地址  http://172.17.13.10:8001/v1/pilot/workspace/update
  // http://192.168.31.103:8001/v1/pilot/workspace/update
  axios.post('https://nansha.taient.com/backend/v1/pilot/workspace/update', {
    id: shareuniqueId,
    workspace: {
      id: shareuniqueId,
      chatHistory: chatHistory || [],
    },

  }).then(async resType => {
    if (resType) {
      // console.log("resType", resType);
    }
  })
}

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // 保存新回调
  useEffect(() => {
    savedCallback.current = callback;
  });

  // 建立 interval
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default function App() {
  const wrapper = useRef();
  const { messages, appendMsg, setTyping } = useMessages([]);

  const ttsPlay = function (event, params) {
    const text = document.getElementById('tts').getAttribute("voice-text")
    const lastStepId = document.getElementById('tts').getAttribute("last-step-id")
    // console.log('last-step-id', lastStepId)
    if (lastStepId) {
      // 如存在，需要将上一次的语音图标置为默认
      document.getElementById('sid-' + lastStepId).setAttribute('src', '/images/voice.png')
      document.getElementById('tts').removeAttribute("last-step-id")
    }
    const locale = localStorage.getItem('lang') || CHATUI_CONFIG.lang
    let voiceName = 'x4_panting'
    if (locale === 'zh-HK') {
      voiceName = 'x3_xiaoyue'
    } else if (locale === 'en-US') {
      voiceName = 'x4_enus_lucy_education'
    }
    if (text && text.length > 0) {
      // 调用xunfei的TTS接口，播放语音
      // zh-HK：x3_xiaoyue
      // en-US：x4_enus_lucy_education
      ttsRecorder.setParams({
        speed: 55,
        voice: 85,
        pitch: 50,
        voiceName: voiceName,
        // voiceName: 'x4_panting',
        tte: 'UTF8',
        text: text
      })

      ttsRecorder.start()
    } else {
      // 停止操作
      ttsRecorder.stop()
    }
  }

  useEffect(() => {
    const localeUseEffect = localStorage.getItem('lang') || CHATUI_CONFIG.lang
    window.locale = localeUseEffect
    const lastlocale = localeUseEffect.substring(localeUseEffect.length - 2)
    const texts = languageText[lastlocale];
    window.locale = texts.locale
    window.localeInpunt = texts.localeInpunt
    document.title = texts.title;
    recStart();
    bot = new window.ChatSDK({
      root: wrapper.current,
      components: {
        "adaptable-action-card":
          "https://nansha.taient.com/chatui/scripts/card-index.js",
        slot: "https://nansha.taient.com/chatui/scripts/slot.js",
      },
      makeRecorder({ ctx }) {
        return {
          canRecord,
          async onStart() {
            // 开始录音
            recResume();
            voiceResult = ""
            resultText = ""
            resultTextTemp = ""
            console.log("Ready to receive a voice command.");
            canRecord = true;
            await connectWebSocket();
            console.log("Finish ready to receive a voice command.");
          },
          onEnd() {
            // 停止录音
            console.log("onEnd voice: ");
            canRecord = false;
            recStop();

            setTimeout(() => {
              if (voiceResult && voiceResult.length > 0) {
                console.log("sending voice: " + voiceResult);
                ctx.postMessage({
                  type: "text",
                  content: { text: voiceResult },
                });
                recStart(); // 重新开始录音
              }
              voiceResult = ""
              resultText = ""
              resultTextTemp = ""
            }, 2000);
          },
          onCancel() {
            // 录音
            console.log("onCancel voice: ");
            canRecord = false;
            recStop();
            recStart();
            voiceResult = ""
            resultText = ""
            resultTextTemp = ""
          },
        };
      },
      makeSocket({ ctx }) {
        // TODO: 从服务端获取，正式实现时需要接入服务端的socket
        // 连接 ws
        const streamingWs = new WebSocket('wss://nansha.taient.com/ws');
        // const streamingWs = new WebSocket('ws://127.0.0.1:5124');

        let message_id;
        let message_send = "";
        let current_answer = ""

        streamingWs.onopen = (e) => {
        }

        // 当收到消息时
        streamingWs.onmessage = (e) => {
          const data = JSON.parse(e.data);
          // console.log('ws message', data, current_answer, step); 
          if (data.step !== step) {
            // console.log("step not match", data.step, step);
            setTyping(false);
            return;
          }

          // 展示消息内容
          // appendMessage
          // message
          // fileItem
          if (data.appendMessage && data.step === step) {
            current_answer = current_answer + data.appendMessage
            ctx.updateMessage(message_id, {
              type: 'text',
              content: {
                text: current_answer,
              },
            });
          } else if (data.message && data.step === step) {
            ctx.updateMessage(message_id, {
              type: 'text',
              content: {
                text: data.message,
              }
            });
          }

          if (data.endMessage && data.step === step) {
            saveChatRecord(message_send, current_answer, data.topic);

            // 发送一条转语音的链接
            let title = "点击听语音"
            if (locale === 'zh-HK') {
              title = "點擊聽語音"
            } else if (locale === 'en-US') {
              title = "Click to Listen"
            }
            ctx.appendMessage({
              id: message_id,
              type: "card",
              content: {
                code: "slot", // 卡片code
                data: {
                  list: [
                    {
                      "size": "small",
                      "title": title,
                      "message": current_answer,
                      "step": data.step,
                    }
                  ]
                }, // 卡片数据 
              },
            })
          }

          if (data.fileItem && data.step === step) {
            // 发送一条文件链接信息
            ctx.appendMessage({
              id: message_id,
              type: "card",
              content: {
                code: "adaptable-action-card", // 卡片code
                data: {
                  title: data.fileItem.title, // 卡片标题
                  picUrl: data.fileItem.image, // 卡片图片
                  content: data.fileItem.content, // 卡片内容
                  actionList: [
                    {
                      text: "",
                      action: "openWindow",
                      style: "",
                      param: {
                        url: data.fileItem.url,
                      },
                    },
                  ],
                }, // 卡片数据
              },
            })
          }

          if (data.endMessage && data.step === step) {
            message_id = ""
            current_answer = ""
          }
        };

        // 当结束服务的时候，提示用户
        streamingWs.onclose = (e) => {
          console.log('ws close', e);
          // ctx.appendMessage({
          //   type: 'system',
          //   content: {
          //     text: '已退出服务',
          //   },
          // });
        };

        return {
          // 把用户的信息发给后端
          send(msg) {
            const locale = localStorage.getItem('lang') || CHATUI_CONFIG.lang
            console.log("locale=========>", locale)
            // console.log('把用户的信息发给后端', msg, streamingWs.readyState)
            step++;
            current_answer = ""
            if (shareuniqueId == "") {
              // 如果没有，就生成一个新的uuID
              shareuniqueId = uuidv4();
            }

            message_id = uuidv4();
            message_send = msg.content.text;
            // 发送一条占位消息
            ctx.appendMessage({
              id: message_id,
              type: "typing"
            })
            msg.userId = shareuniqueId;
            msg.workspace = JSON.stringify({
              chatHistory: chat,
              userId: shareuniqueId,
              locale: locale,
            });
            msg.step = step;

            if (streamingWs.readyState === streamingWs.OPEN) {
              streamingWs.send(JSON.stringify(msg));
            }
          },
          close() {
            console.log('关闭到服务器的链接')
            streamingWs.close();
          },
        };
      },
      config: {
        inputType: "text",
        navbar: {
          // title: "广州南沙人才政策通",
          title: texts.title,
        },
        robot: {
          avatar: "/images/t-ant-avatar.png",
          // name: "南沙人才政策虚拟助理",
          name: texts.name
        },
        user: {
          avatar: "/images/user-avatar.png",
          name: "Albert",
        },
        quickReplies: [

          // {
          //   name: '问生活补贴申报条件',
          //   type: 'text',
          //   text: '2024年南沙区新引进学历人才生活补贴的申报条件是什么？',
          // },
          // {
          //   name: '问生活补贴申报流程',
          //   type: 'text',
          //   text: '请介绍2024年南沙区新引进学历人才生活补贴的申报流程',
          // },
          // {
          //   name: '问生活补贴的标准',
          //   type: 'text',
          //   text: '2024年南沙区新引进学历人才生活补贴的标准是怎么规定的？',
          // },
          // {
          //   name: '问引进人才奖励',
          //   type: 'text',
          //   text: '对新引进落户人才的生活补贴的奖励政策是什么？',
          // },
          // {
          //   name: '问归国创业支持',
          //   type: 'text',
          //   text: '海外留学归国创业的政策支持标准是什么？',
          // },
          // {
          //   name: '问高层次团队申报条件',
          //   type: 'text',
          //   text: '高层次人才创新创业团队的申报条件分别是什么？',
          // },
          // {
          //   name: '问高技能工匠遴选方式',
          //   type: 'text',
          //   text: '请介绍高技能工匠的遴选方式',
          // },
          // {
          //   name: '问国际知名高校博士支持',
          //   type: 'text',
          //   text: '引进国际知名高校博士的支持政策是什么？',
          // },
          // {
          //   name: '问入户条件',
          //   type: 'text',
          //   text: '请问办理在职人员入户广州市南沙区，需要具备什么条件？',
          // },
          // {
          //   name: '问入户流程',
          //   type: 'text',
          //   text: '请问办理在职人员落户广州南沙的具体申请流程是什么？',
          // },
          // {
          //   name: '问入户材料',
          //   type: 'text',
          //   text: '我要落户广州南沙，需要准备哪些具体的材料？',
          // },
          // {
          //   name: '问档案转入现场办理',
          //   type: 'text',
          //   text: '请问档案接收的现场办理流程是怎么样？',
          // },
          // {
          //   name: '问档案转入线上办理',
          //   type: 'text',
          //   text: '请问档案接收的线上办理流程是怎么样？',
          // },
          // {
          //   name: '问港澳青年就业补贴',
          //   type: 'text',
          //   text: '我是港澳青年，本科学历，在南沙工作超过六个月了，请问有什么政策补贴吗？',
          // },
          // {
          //   name: '问港澳青年实习补贴',
          //   type: 'text',
          //   text: '港澳青年来这南沙实习有什么政策支持？',
          // },
          // {
          //   name: '问港澳青年创业支持',
          //   type: 'text',
          //   text: '我是港澳青年，想在南沙创业，有什么政策补贴或奖励吗？',
          // },
          // {
          //   name: '问港澳青年生活福利',
          //   type: 'text',
          //   text: '我是港澳青年，在南沙工作居住，请问生活上有什么政策福利吗？',
          // },
          // {
          //   name: '问港澳公司落户',
          //   type: 'text',
          //   text: '我是香港青年，我的公司迁入南沙落户有什么政策支持吗？公司迁入南沙需要租场地，有什么补贴吗？',
          // },

        ],
        agent: {
          quickReply: {
            icon: 'message',
            name: '召唤在线客服',
            isHighlight: true,
          },
        },
        messages: [
          {
            type: "text",
            content: {
              // text: "您好！请问您有什么问题需要咨询？我会尽力为您提供有关广州南沙新区（自贸试验区）人才政策的实施细则的相关信息。",
              text: texts.text,
            },
            user: {
              avatar: "/images/t-ant-avatar.png",
              // name: "南沙人才政策虚拟助理",
              name: texts.name
            },
            createdAt: Date.now(),
            hasTime: true,
          }
        ],
      },
      requests: {
        send: function (msg) {
          // uuID
          if (shareuniqueId == "") {
            // 如果没有，就生成一个新的uuID
            shareuniqueId = uuidv4();
          }
          datamessage = msg.content;
          let chatHistory = chat;
          // console.log("chatHistory-->发送", chatHistory);
          // 发送文本消息时
          if (msg.type === "text") {
            step++;
            return {
              url: "https://nansha.taient.com/agent/xfpolicy",
              // url: "http://127.0.0.1:5122/agent/xfpolicy",
              type: "POST",
              data: {
                step,
                q: datamessage.text,
                workspace: JSON.stringify({
                  chatHistory: chatHistory,
                  userId: shareuniqueId,
                }),
              },
            };
          }
          // ... 其它消息类型的处理
        },
      },
      handlers: {
        parseResponse: function (res, requestType) {
          // 根据 requestType 处理数据
          // console.log("res", res, "requestType", requestType);
          if (requestType === "send" && res.message) {
            saveChatRecord(datamessage.text, res.message);
            if (res.url) {
              return [
                {
                  type: "text",
                  content: { text: res.message },
                  position: "left",
                  hasTime: true,
                  createdAt: Date.now(),
                },
                {
                  type: "card",
                  content: {
                    code: "adaptable-action-card", // 卡片code
                    data: {
                      title: res.title, // 卡片标题
                      picUrl: "/images/nanshalandscape.jpg",
                      content: "点击打开查看政策详情", // 卡片内容
                      actionList: [
                        {
                          text: "",
                          action: "openWindow",
                          style: "",
                          param: {
                            url: res.url,
                          },
                        },
                      ],
                    }, // 卡片数据
                  },
                }
              ];
            } else {
              let title = "点击听语音"
              if (locale === 'zh-HK') {
                title = "點擊聽語音"
              } else if (locale === 'en-US') {
                title = "Click to Listen"
              }
              let results = [{
                type: "text",
                content: { text: res.message },
                // user: { avatar: "/images/t-ant-avatar.png", name: "南沙人才政策虚拟助理" },
                user: { avatar: "/images/t-ant-avatar.png", name: texts.name },
                position: "left",
                hasTime: true,
                createdAt: Date.now(),
              },
              {
                type: "card",
                content: {
                  code: "slot", // 卡片code
                  data: {
                    list: [
                      {
                        "size": "small",
                        "title": title,
                        "message": res.message,
                        "step": res.step,
                      }
                    ]
                  }, // 卡片数据 
                },
              }]
              if (res.items && res.items.length > 0) {
                let item = res.items[0];
                results.push({
                  type: "card",
                  content: {
                    code: "adaptable-action-card", // 卡片code
                    data: {
                      title: item.title, // 卡片标题
                      picUrl: "/images/nanshalandscape.jpg",
                      content: item.content, // 卡片内容
                      actionList: [
                        {
                          text: "",
                          action: "openWindow",
                          style: "",
                          param: {
                            url: item.url,
                          },
                        },
                      ],
                    }, // 卡片数据
                  },
                });
              }

              return results
            }
          }

          // 不需要处理的数据直接返回
          return res;
        },
      },
    });
    bot.run();

    // 直接切换socket模式
    bot.getCtx().appendMessage({
      type: 'cmd',
      content: {
        code: 'agent_join'
      }
    })
  }, []);

  return <div style={{ height: "100%" }}>
    <div style={{ height: "100%" }} ref={wrapper} />
    <div id="tts" style={{ position: "absolute", width: "0px" }} onClick={ttsPlay}></div>
  </div>
}

/**
 * 获取websocket url
 * 该接口需要后端提供，这里为了方便前端处理
 */
async function getWebSocketUrl() {
  const locale = localStorage.getItem('lang') || CHATUI_CONFIG.lang
  const socketUrl = await axios.post('https://nansha.taient.com/chatui/api/iaturl', {
    app_id: XF_APPID,
    locale: locale
  })

  console.log("socketUrl", socketUrl.data.iatUrl)
  return socketUrl.data.iatUrl
}

function toBase64(buffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

function countdown() {
  console.log("countdown");
  let seconds = 60;
  countdownInterval = setInterval(() => {
    seconds = seconds - 1;
    if (seconds <= 0) {
      clearInterval(countdownInterval);
      recStop();
      recStart(); // 重新开始录音
      voiceResult = ""
      resultText = ""
      resultTextTemp = ""
    } else {
      btnControl.innerText = `录音中（${seconds}s）`;
    }
  }, 1000);
  console.log("countdown done");
}

let resultText = "";
let btnStatus = "UNDEFINED"; // "UNDEFINED" "CONNECTING" "OPEN" "CLOSING" "CLOSED"
const btnControl = {};
// const recorder = new RecorderManager("/scripts");

let iatWS;
let resultTextTemp = "";
let countdownInterval;

function renderResult(resultData) {
  // 识别结束
  console.log("renderResult", resultData);
  let jsonData = JSON.parse(resultData);
  const locale = localStorage.getItem('lang') || CHATUI_CONFIG.lang
  if (locale === 'zh-HK') {
    if (jsonData.header && jsonData.header.name === 'TranscriptionResultChanged') {
      if (jsonData.payload && jsonData.payload.result) {
        let result = jsonData.payload.result
        voiceResult = result
      }
    } else if (jsonData.header && jsonData.header.name === 'TranscriptionCompleted') {
      if (jsonData.payload && jsonData.payload.result) {
        let result = jsonData.payload.result
        voiceResult = result
      }
      iatWS.close();
    } else if (jsonData.header && jsonData.header.name === 'SentenceEnd') {
      if (jsonData.payload && jsonData.payload.result) {
        let result = jsonData.payload.result
        voiceResult = result
      }
      iatWS.close();
    }
  } else {
    if (jsonData.data && jsonData.data.result) {
      let data = jsonData.data.result;
      let str = "";
      let ws = data.ws;
      for (let i = 0; i < ws.length; i++) {
        str = str + ws[i].cw[0].w;
      }
      // 开启wpgs会有此字段(前提：在控制台开通动态修正功能)
      // 取值为 "apd"时表示该片结果是追加到前面的最终结果；取值为"rpl" 时表示替换前面的部分结果，替换范围为rg字段
      if (data.pgs) {
        if (data.pgs === "apd") {
          // 将resultTextTemp同步给resultText
          resultText = resultTextTemp;
        }
        // 将结果存储在resultTextTemp中
        resultTextTemp = resultText + str;
      } else {
        resultText = resultText + str;
      }
      voiceResult = resultTextTemp || resultText || "";
    }
    if (jsonData.code === 0 && jsonData.data.status === 2) {
      iatWS.close();
    }
    if (jsonData.code !== 0) {
      iatWS.close();
      console.error(jsonData);
    }
  }
}

async function connectWebSocket() {
  if (!canRecord) {
    return;
  }
  const locale = localStorage.getItem('lang') || CHATUI_CONFIG.lang
  const websocketUrl = await getWebSocketUrl();
  if ("WebSocket" in window) {
    iatWS = new WebSocket(websocketUrl);
  } else if ("MozWebSocket" in window) {
    iatWS = new MozWebSocket(websocketUrl);
  } else {
    alert("浏览器不支持WebSocket");
    return;
  }
  iatWS.onopen = (e) => {
    console.log("onopen");

    // 开始录音
    var params = {
      common: {
        app_id: XF_APPID,
      },
      business: {
        language: "zh_cn",
        domain: "iat",
        accent: "mandarin",
        vad_eos: 5000,
        dwa: "wpgs"
      },
      data: {
        status: 0,
        format: "audio/L16;rate=16000",
        encoding: "raw",
      },
    };

    iatWS.task_id = generateUniqueID();
    const instruction = {
      "header": {
          "appkey": ALIYUN_APPKEY,
          "message_id": generateUniqueID(),
          "task_id": iatWS.task_id,
          "namespace": "SpeechTranscriber",
          "name": "StartTranscription"
      },
      "payload": {
          "format": "PCM",
          "sample_rate": 16000,
          "enable_intermediate_result": true,
          "enable_punctuation_prediction": true,
          "enable_inverse_text_normalization": true,
          "max_sentence_silence": 2000,
      }
    }
    if (locale === 'zh-HK') {
      console.log("zh-HK instruction", instruction);
      iatWS.send(JSON.stringify(instruction));
    } else {
      iatWS.send(JSON.stringify(params));
    }
    console.log("after send");
  };
  iatWS.onmessage = (e) => {
    console.log("renderResult", e);
    renderResult(e.data);
  };
  iatWS.onerror = (e) => {
    console.error(e);
    recStop();
    recStart(); // 重新开始录音
  };
  iatWS.onclose = (e) => {
    console.log('iatWS close', e);
    recStop();
    recStart(); // 重新开始录音
  };
}

var generateUniqueID = function () {
  return Array(32)
      .fill(0)
      .map(() => Math.floor(Math.random() * 16).toString(16))
      .join('');
}

//=====实时处理核心函数==========
var RealTimeSendTry = function (buffers, bufferSampleRate, isClose) {
  if (realTimeSendTryChunks == null) {
    realTimeSendTryNumber = 0;
    transferUploadNumberMax = 0;
    realTimeSendTryChunk = null;
    realTimeSendTryChunks = [];
  };
  //配置有效性检查
  if (testBitRate == 16 && SendFrameSize % 2 == 1) {
    console.log("16位pcm SendFrameSize 必须为2的整数倍", 1);
    return;
  };

  var pcm = [], pcmSampleRate = 0;
  if (buffers.length > 0) {
    //借用SampleData函数进行数据的连续处理，采样率转换是顺带的，得到新的pcm数据
    var chunk = Recorder.SampleData(buffers, bufferSampleRate, testSampleRate, realTimeSendTryChunk);

    //清理已处理完的缓冲数据，释放内存以支持长时间录音，最后完成录音时不能调用stop，因为数据已经被清掉了
    for (var i = realTimeSendTryChunk ? realTimeSendTryChunk.index : 0; i < chunk.index; i++) {
      buffers[i] = null;
    };
    realTimeSendTryChunk = chunk;//此时的chunk.data就是原始的音频16位pcm数据（小端LE），直接保存即为16位pcm文件、加个wav头即为wav文件、丢给mp3编码器转一下码即为mp3文件

    pcm = chunk.data;
    pcmSampleRate = chunk.sampleRate;

    if (pcmSampleRate != testSampleRate)//除非是onProcess给的bufferSampleRate低于testSampleRate
      throw new Error("不应该出现pcm采样率" + pcmSampleRate + "和需要的采样率" + testSampleRate + "不一致");
  };

  //将pcm数据丢进缓冲，凑够一帧发送，缓冲内的数据可能有多帧，循环切分发送
  if (pcm.length > 0) {
    realTimeSendTryChunks.push({ pcm: pcm, pcmSampleRate: pcmSampleRate });
  };

  //从缓冲中切出一帧数据
  var chunkSize = SendFrameSize / (testBitRate / 8);//8位时需要的采样数和帧大小一致，16位时采样数为帧大小的一半
  var pcm = new Int16Array(chunkSize), pcmSampleRate = 0;
  var pcmOK = false, pcmLen = 0;
  for1: for (var i1 = 0; i1 < realTimeSendTryChunks.length; i1++) {
    var chunk = realTimeSendTryChunks[i1];
    pcmSampleRate = chunk.pcmSampleRate;

    for (var i2 = chunk.offset || 0; i2 < chunk.pcm.length; i2++) {
      pcm[pcmLen] = chunk.pcm[i2];
      pcmLen++;

      //满一帧了，清除已消费掉的缓冲
      if (pcmLen == chunkSize) {
        pcmOK = true;
        chunk.offset = i2 + 1;
        for (var i3 = 0; i3 < i1; i3++) {
          realTimeSendTryChunks.splice(0, 1);
        };
        break for1;
      }
    }
  };

  //缓冲的数据不够一帧时，不发送 或者 是结束了
  if (!pcmOK) {
    if (isClose) {
      // var number=++realTimeSendTryNumber;
      console.log('准备发送最后一帧数据', pcmOK, isClose)
      // TransferUpload(number,null,0,null,isClose);
      var number = ++realTimeSendTryNumber;
      var encStartTime = Date.now();
      var recMock = Recorder({
        type: "pcm"
        , sampleRate: testSampleRate //需要转换成的采样率
        , bitRate: testBitRate //需要转换成的比特率
      });
      recMock.mock(pcm, pcmSampleRate);
      recMock.stop(function (blob, duration) {
        blob.encTime = Date.now() - encStartTime;

        //转码好就推入传输
        TransferUpload(number, blob, duration, recMock, true, true);
      }, function (msg) {
        //转码错误？没想到什么时候会产生错误！
        console.log("不应该出现的错误:" + msg, 1);
      });
    };
    return;
  };

  //16位pcm格式可以不经过mock转码，直接发送new Blob([pcm.buffer],{type:"audio/pcm"}) 但8位的就必须转码，通用起见，均转码处理，pcm转码速度极快
  var number = ++realTimeSendTryNumber;
  var encStartTime = Date.now();
  var recMock = Recorder({
    type: "pcm"
    , sampleRate: testSampleRate //需要转换成的采样率
    , bitRate: testBitRate //需要转换成的比特率
  });
  recMock.mock(pcm, pcmSampleRate);
  recMock.stop(function (blob, duration) {
    blob.encTime = Date.now() - encStartTime;

    //转码好就推入传输
    TransferUpload(number, blob, duration, recMock, false);

    //循环调用，继续切分缓冲中的数据帧，直到不够一帧
    RealTimeSendTry([], 0, isClose);
  }, function (msg) {
    //转码错误？没想到什么时候会产生错误！
    console.log("不应该出现的错误:" + msg, 2);
  });
};

//=====数据传输函数==========
var TransferUpload = function (number, blobOrNull, duration, blobRec, isClose, lastChunk) {
  transferUploadNumberMax = Math.max(transferUploadNumberMax, number);
  if (blobOrNull) {
    var blob = blobOrNull;
    var encTime = blob.encTime;

    //*********发送方式一：Base64文本发送***************
    var reader = new FileReader();
    const locale = localStorage.getItem('lang') || CHATUI_CONFIG.lang
    reader.onloadend = function () {

      //可以实现
      //WebSocket send(base64) ...
      //WebRTC send(base64) ...
      //XMLHttpRequest send(base64) ...

      //这里啥也不干
      if (locale === 'zh-HK') {
        if (iatWS && iatWS.readyState === iatWS.OPEN) {
          // 把base64转成blob
          const base64 = (/.+;\s*base64\s*,\s*(.+)$/i.exec(reader.result) || [])[1];
          console.log("准备从这里发送ALIYUN", iatWS.readyState, isClose, base64.length);
          // 将base64转成pcm
          const pcm = atob(base64);
          let pcmBuffer = new ArrayBuffer(pcm.length);
          let pcmView = new Uint8Array(pcmBuffer);
          for (let i = 0; i < pcm.length; i++) {
            pcmView[i] = pcm.charCodeAt(i);
          }

          // 将pcm转成blob
          while (pcmView.length * 2 >= 3200) {
            const chunk = pcmView.slice(0, 3200 / 2);
            const newblob = new Blob([chunk], { type: "audio/pcm" });
            iatWS.send(newblob);
            pcmView = pcmView.slice(3200 / 2);
            console.log('发送文件块 after', pcmView.length)
          }

          console.log('pcmView left', pcmView.length)
          if (pcmView.length > 0) {
            console.log('发送最后的文件块', pcmView.length, pcmView)
            const newblob = new Blob([pcmView], { type: "audio/pcm" });
            iatWS.send(newblob);
          }

          if (lastChunk) {
            console.log('发送stopTranscription指令')
            const instruction = {
              "header": {
                  "appkey": ALIYUN_APPKEY,
                  "message_id": generateUniqueID(),
                  "task_id": iatWS.task_id || generateUniqueID(),
                  "namespace": "SpeechTranscriber",
                  "name": "StopTranscription"
              }
            }
            iatWS.send(JSON.stringify(instruction));
          }
        }
      } else {
        var base64 = (/.+;\s*base64\s*,\s*(.+)$/i.exec(reader.result) || [])[1];
        console.log("准备从这里发送讯飞", iatWS.readyState, isClose, base64.length);
        if (iatWS.readyState === iatWS.OPEN) {
          iatWS.send(
            JSON.stringify({
              data: {
                status: isClose ? 2 : 1,
                format: "audio/L16;rate=16000",
                encoding: "raw",
                audio: base64,
              },
            })
          );
        }
      }
    };
    reader.readAsDataURL(blob);

    //*********发送方式二：Blob二进制发送***************
    //可以实现
    //WebSocket send(blob) ...
    //WebRTC send(blob) ...
    //XMLHttpRequest send(blob) ...


    //****这里仅 console.log一下 意思意思****
    var numberFail = number < transferUploadNumberMax ? '<span style="color:red">顺序错乱的数据，如果要求不高可以直接丢弃</span>' : "";
    var logMsg = "No." + (number < 100 ? ("000" + number).substr(-3) : number) + numberFail;

    console.log(blob, duration, blobRec, logMsg + "花" + ("___" + encTime).substr(-3) + "ms");
  };

  if (isClose) {
    console.log("No." + (number < 100 ? ("000" + number).substr(-3) : number) + ":已停止传输");
  };
};

//=====pcm文件合并核心函数==========
Recorder.PCMMerge = function (fileBytesList, bitRate, sampleRate, True, False) {
  //计算所有文件总长度
  var size = 0;
  for (var i = 0; i < fileBytesList.length; i++) {
    size += fileBytesList[i].byteLength;
  };

  //全部直接拼接到一起
  var fileBytes = new Uint8Array(size);
  var pos = 0;
  for (var i = 0; i < fileBytesList.length; i++) {
    var bytes = fileBytesList[i];
    fileBytes.set(bytes, pos);
    pos += bytes.byteLength;
  };

  //计算合并后的总时长
  var duration = Math.round(size * 8 / bitRate / sampleRate * 1000);

  True(fileBytes, duration, { bitRate: bitRate, sampleRate: sampleRate });
};

function recStart() {
  if (rec) {
    rec.close();
  };
  rec = Recorder({
    type: "unknown"
    , onProcess: function (buffers, powerLevel, bufferDuration, bufferSampleRate) {
      //推入实时处理，因为是unknown格式，buffers和rec.buffers是完全相同的，只需清理buffers就能释放内存。
      RealTimeSendTry(buffers, bufferSampleRate, false);
    }
  });

  var t = setTimeout(function () {
    console.log("无法录音：权限请求被忽略（超时假装手动点击了确认对话框）", 1);
  }, 8000);

  rec.open(function () {//打开麦克风授权获得相关资源
    clearTimeout(t);
    rec.start();//开始录音
    rec.pause();// 默认暂停等待

    RealTimeSendTryReset();//重置环境，开始录音时必须调用一次
  }, function (msg, isUserNotAllow) {
    clearTimeout(t);
    console.log((isUserNotAllow ? "UserNotAllow，" : "") + "无法录音:" + msg, 1);
  });
};

function recStop() {
  rec.close();//直接close掉即可，这个例子不需要获得最终的音频文件

  RealTimeSendTry([], 0, true);//最后一次发送
};
/**暂停录音**/
function recPause() {
  if (rec && Recorder.IsOpen()) {
    rec.pause();
  } else {
    // reclog("未打开录音", 1);
  };
};
/**恢复录音**/
function recResume() {
  if (rec && Recorder.IsOpen()) {
    rec.resume();
  } else {
    // reclog("未打开录音", 1);
  };
};

async function getTTSSocketUrl() {
  // FIXME 需要换成服务器的地址
  const socketUrl = await axios.post('https://nansha.taient.com/chatui/api/ttsurl', {
    app_id: XF_APPID,
  })

  return socketUrl.data.ttsUrl
}

class TTSRecorder {
  constructor({
    speed = 50,
    voice = 50,
    pitch = 50,
    // voiceName = 'xiaoyan',
    voiceName = 'x4_panting',
    appId = XF_APPID,
    text = '',
    tte = 'UTF8',
    defaultText = '请输入您要说的的话',
  } = {}) {
    this.speed = speed
    this.voice = voice
    this.pitch = pitch
    this.voiceName = voiceName
    this.text = text
    this.tte = tte
    this.defaultText = defaultText
    this.appId = appId
    this.audioData = []
    this.rawAudioData = []
    this.audioDataOffset = 0
    this.status = 'init'
    transWorker.onmessage = (e) => {
      this.audioData.push(...e.data.data)
      this.rawAudioData.push(...e.data.rawAudioData)
    }
  }
  // 修改录音听写状态
  setStatus(status) {
    this.onWillStatusChange && this.onWillStatusChange(this.status, status)
    this.status = status
  }
  // 设置合成相关参数
  setParams({ speed, voice, pitch, text, voiceName, tte }) {
    speed !== undefined && (this.speed = speed)
    voice !== undefined && (this.voice = voice)
    pitch !== undefined && (this.pitch = pitch)
    text && (this.text = text)
    tte && (this.tte = tte)
    voiceName && (this.voiceName = voiceName)
    this.resetAudio()
  }
  // 连接websocket
  async connectWebSocket() {
    this.setStatus('ttsing')
    const url = await getTTSSocketUrl()
    let ttsWS
    if ('WebSocket' in window) {
      ttsWS = new WebSocket(url)
    } else if ('MozWebSocket' in window) {
      ttsWS = new MozWebSocket(url)
    } else {
      alert('浏览器不支持WebSocket')
      return
    }
    this.ttsWS = ttsWS
    ttsWS.onopen = e => {
      this.webSocketSend()
      this.playTimeout = setTimeout(() => {
        this.audioPlay()
      }, 1000)
    }
    ttsWS.onmessage = e => {
      this.result(e.data)
    }
    ttsWS.onerror = e => {
      clearTimeout(this.playTimeout)
      this.setStatus('errorTTS')
      alert('WebSocket报错，请f12查看详情')
      console.error(`详情查看：${encodeURI(url.replace('wss:', 'https:'))}`)
    }
    ttsWS.onclose = e => {
      // console.log(e)
    }
  }
  // 处理音频数据
  transToAudioData(audioData) { }
  // websocket发送数据
  webSocketSend() {
    var params = {
      common: {
        app_id: this.appId, // APPID
      },
      business: {
        aue: 'raw',
        auf: 'audio/L16;rate=8000',
        vcn: this.voiceName,
        speed: this.speed,
        volume: this.voice,
        pitch: this.pitch,
        bgs: 0,
        tte: this.tte,
      },
      data: {
        status: 2,
        text: this.encodeText(
          this.text || this.defaultText,
          this.tte === 'unicode' ? 'base64&utf16le' : ''
        )
      },
    }
    this.ttsWS.send(JSON.stringify(params))
  }
  encodeText(text, encoding) {
    switch (encoding) {
      case 'utf16le': {
        let buf = new ArrayBuffer(text.length * 4)
        let bufView = new Uint16Array(buf)
        for (let i = 0, strlen = text.length; i < strlen; i++) {
          bufView[i] = text.charCodeAt(i)
        }
        return buf
      }
      case 'buffer2Base64': {
        let binary = ''
        let bytes = new Uint8Array(text)
        let len = bytes.byteLength
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i])
        }
        return window.btoa(binary)
      }
      case 'base64&utf16le': {
        return this.encodeText(this.encodeText(text, 'utf16le'), 'buffer2Base64')
      }
      default: {
        return Base64.encode(text)
      }
    }
  }
  // websocket接收数据的处理
  result(resultData) {
    let jsonData = JSON.parse(resultData)
    // 合成失败
    if (jsonData.code !== 0) {
      alert(`合成失败: ${jsonData.code}:${jsonData.message}`)
      console.error(`${jsonData.code}:${jsonData.message}`)
      this.resetAudio()
      return
    }
    transWorker.postMessage(jsonData.data.audio)

    if (jsonData.code === 0 && jsonData.data.status === 2) {
      this.ttsWS.close()
    }
  }
  // 重置音频数据
  resetAudio() {
    this.audioStop()
    this.setStatus('init')
    this.audioDataOffset = 0
    this.audioData = []
    this.rawAudioData = []
    this.ttsWS && this.ttsWS.close()
    clearTimeout(this.playTimeout)
  }
  // 音频初始化
  audioInit() {
    let AudioContext = window.AudioContext || window.webkitAudioContext
    if (AudioContext) {
      this.audioContext = new AudioContext()
      this.audioContext.resume()
      this.audioDataOffset = 0
    }
  }
  // 音频播放
  audioPlay() {
    this.setStatus('play')
    let audioData = this.audioData.slice(this.audioDataOffset)
    this.audioDataOffset += audioData.length
    let audioBuffer = this.audioContext.createBuffer(1, audioData.length, 22050)
    let nowBuffering = audioBuffer.getChannelData(0)
    if (audioBuffer.copyToChannel) {
      audioBuffer.copyToChannel(new Float32Array(audioData), 0, 0)
    } else {
      for (let i = 0; i < audioData.length; i++) {
        nowBuffering[i] = audioData[i]
      }
    }
    let bufferSource = this.bufferSource = this.audioContext.createBufferSource()
    bufferSource.buffer = audioBuffer
    bufferSource.connect(this.audioContext.destination)
    bufferSource.start()
    bufferSource.onended = event => {
      console.log('onended')
      if (this.status !== 'play') {
        return
      }
      if (this.audioDataOffset < this.audioData.length) {
        this.audioPlay()
      } else {
        console.log('clear tts')
        document.getElementById('tts').removeAttribute('voice-text')
        const stepId = 'sid-' + document.getElementById('tts').getAttribute('step-id')
        if (document.getElementById(stepId)) {
          document.getElementById(stepId).setAttribute('src', '/images/voice.png')
        }
        document.getElementById('tts').removeAttribute('step-id')

        this.audioStop()
      }
    }
  }
  // 音频播放结束
  audioStop() {
    this.setStatus('endPlay')
    clearTimeout(this.playTimeout)
    this.audioDataOffset = 0
    if (this.bufferSource) {
      try {
        this.bufferSource.stop()
      } catch (e) {
        console.log(e)
      }
    }
  }
  start() {
    if (this.audioData.length) {
      this.audioPlay()
    } else {
      if (!this.audioContext) {
        this.audioInit()
      }
      if (!this.audioContext) {
        alert('该浏览器不支持webAudioApi相关接口')
        return
      }
      this.connectWebSocket()
    }
  }
  stop() {
    this.audioStop()
  }
}

let transWorker = new TransWorker()
let ttsRecorder = new TTSRecorder()