const languageText = {
    CN: {
        topText: '广州南沙人才政策通',
        bottomText: '广州市南沙区人力资源和社会保障局',
        buttonText: '立即开始',
        switchLanguage: '语言：简体中文（点击切换）',
        modalTitle: '切换语言',
        CN: '简体中文（普通话）',
        HK: '繁體中文（粵語對話）',
        US: 'English',
        text: "您好！请问您有什么问题需要咨询？我会尽力为您提供有关广州南沙新区（自贸试验区）人才政策的实施细则的相关信息。",
        name: "南沙人才政策虚拟助理",
        title: "广州南沙人才政策通",
        localeInpunt: "请输入...",
        locale: "zh-CN"
    },
    HK: {
        topText: '廣州南沙人才政策通',
        bottomText: '廣州市南沙區人力資源和社會保障局',
        buttonText: '立即開始',
        switchLanguage: '語言：繁體中文（點擊切換）',
        modalTitle: '切換語言',
        CN: '简体中文（普通话）',
        HK: '繁體中文（粵語對話）',
        US: 'English',
        text: "您好！ 請問您有什麼問題需要諮詢？ 我會盡力為您提供有關廣州南沙新區（自貿試驗區）人才政策的實施細則的相關資訊。",
        name: "南沙人才政策虛擬助理",
        title: "廣州南沙人才政策通",
        localeInpunt: "請輸入...",
        locale: "zh-HK"
    },
    US: {
        topText: 'Guangzhou Nansha Talent Policy Communication',
        bottomText: 'Human Resources and Social Security Bureau of Nansha District, Guangzhou City',
        buttonText: 'Start Now',
        switchLanguage: 'Language: English (click to switch)',
        modalTitle: 'Switch Language',
        CN: '简体中文（普通话）',
        HK: '繁體中文（粵語對話）',
        US: 'English',
        text: "Hello!Do you have any questions to consult pleasepersonnel files? I will do my best to provide you with relevant information on the implementation rules of the talent policy in Guangzhou Nansha New Area (Free Trade Zone).",
        name: "Nansha Talent Policy Virtual Assistant",
        title: "Guangzhou Nansha Talent Policy Communication",
        localeInpunt: "Please enter...",
        locale: "en-US"
    }
};

export default languageText;