import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import Preview from './Preview';
import OpenThePage from './OpenThePage';
import reportWebVitals from './reportWebVitals';
import LanguageConfiguration from './LanguageConfiguration'
import CHATUI_CONFIG from './config';
import './index.css';
import './config.js'; // 引入全局配置文件
const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);
// 定义语言文本映射
const languageText = LanguageConfiguration;
const locale = localStorage.getItem('lang') || CHATUI_CONFIG.lang
const lastlocale = locale.substring(locale.length - 2)
const texts = languageText[lastlocale];
document.title = texts.title;

const AppRoutes = (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<OpenThePage />} />
      <Route path="/app" element={<App />} />
      <Route path="/preview" element={<Preview />} />
    </Routes>
  </BrowserRouter>
);

root.render(AppRoutes);
reportWebVitals();